<template>
  <section id="ligas">
    <div>
      <b-modal
        :active.sync="isLoading"
        :width="400"
        :can-cancel="[false, false, false]"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div class="field" style="margin-top: 25px;">
              <p style="margin-bottom: 15px; text-align: center;">
                Carregando informações, por favor, não recarregue a página até
                que o processo seja concluído.
              </p>
              <progress class="progress is-small is-primary" max="100"
                >15%</progress
              >
            </div>
          </section>
        </div>
      </b-modal>
    </div>

    <div class="columns has-background-bluedark2 is-variable is-gapless">
      <div class="column">
        <b-navbar mobile-burger type="is-bluedark2">
          <template slot="start">
            <b-navbar-item
              tag="div"
              @click="filterStatus('destaque')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-purple': selectStatus == 'destaque' }"
                >Destaque</b
              >
            </b-navbar-item>

            <b-navbar-item
              tag="div"
              @click="filterStatus('pre_venda')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-info': selectStatus == 'pre_venda' }"
                >Pré Venda</b
              >
            </b-navbar-item>

            <b-navbar-item
              tag="div"
              @click="filterStatus('aberta')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-success': selectStatus == 'aberta' }"
                >Aberta</b
              >
            </b-navbar-item>

            <b-navbar-item
              tag="div"
              @click="filterStatus('em_andamento')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-warning': selectStatus == 'em_andamento' }"
                >Em Andamento</b
              >
            </b-navbar-item>

            <b-navbar-item
              tag="div"
              @click="filterStatus('encerrada')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-danger': selectStatus == 'encerrada' }"
                >Encerrada</b
              >
            </b-navbar-item>
          </template>

          <template slot="end">
            <b-navbar-item tag="div" style="cursor: pointer">
              <b-button v-if="ligasTable" type="is-success" @click="addLiga()"
                >Adicionar Liga</b-button
              >
              <b-button
                v-if="ligasTable && envMode == 'development'"
                type="is-warning"
                @click="nextRound()"
                >Avançar da Rodada {{ this.mercado.rodada_atual }} para
                {{
                  this.mercado.rodada_atual == 38
                    ? 1
                    : this.mercado.rodada_atual + 1
                }}</b-button
              >
              <b-button
                v-if="
                  ligasForm && 'add,edit,copy'.indexOf(ligasFormAction) !== -1
                "
                type="is-success"
                @click="saveLiga()"
                >Salvar</b-button
              >
              <!-- <b-button v-if="ligasForm && ligasFormAction == 'delete'" type="is-success" @click="deleteLiga()">Confirmar</b-button> -->
              <b-button
                v-if="ligasFormLevel2"
                type="is-purple"
                @click="redoLiga()"
                >Excluir Premiação</b-button
              >
              <b-button
                v-if="ligasForm || ligasFormLevel2"
                type="is-danger"
                outlined
                @click="resetForm()"
                >Cancelar</b-button
              >
            </b-navbar-item>
          </template>
        </b-navbar>
      </div>
    </div>

    <div class="has-background-bluedark2">
      <b-table
        blueDark2
        v-if="ligasTable"
        :loading="isLoading"
        :data="ligas"
        :mobile-cards="false"
        :draggable="'pre_venda,aberta,destaque'.indexOf(selectStatus) !== -1"
        @dragstart="dragstart"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave"
        detailed
        detail-key="id"
        :show-detail-icon="true"
        :default-sort="
          'pre_venda,aberta,destaque'.indexOf(selectStatus) !== -1
            ? 'ordem'
            : ''
        "
        :default-sort-direction="
          'pre_venda,aberta,destaque'.indexOf(selectStatus) !== -1 ? 'asc' : ''
        "
      >
        <!--         
        sticky-header	
        height="75vh"
        paginated
        :per-page="15"
        pagination-size="is-small"
-->

        <template slot-scope="props" slot="header">
          <div v-if="props.column.sortable" class="label-table centralize-row">
            <span v-if="props.column.sortable">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div v-else class="label-table centralize-row">
            <p>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column label="">
            <div class="columns is-multiline">
              <div class="column is-full">
                <b-button
                  type="is-primary"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="search"
                  outlined
                  @click="viewLiga(props.row)"
                />
                <b-button
                  type="is-info"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="copy"
                  outlined
                  @click="copyLiga(props.row)"
                />
                <b-button
                  v-if="
                    'aberta,pre_venda'.indexOf(props.row.status) !== -1 &&
                      (!props.row.times_inscritos ||
                        props.row.times_inscritos == 0)
                  "
                  type="is-warning"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="edit"
                  outlined
                  @click="editLiga(props.row)"
                />
                <b-button
                  v-if="
                    'aberta,pre_venda'.indexOf(props.row.status) !== -1 &&
                      (!props.row.times_inscritos ||
                        props.row.times_inscritos == 0)
                  "
                  type="is-danger"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="times"
                  outlined
                  @click="deleteLiga(props.row)"
                />
                <b-button
                  v-if="
                    'aberta,pre_venda'.indexOf(props.row.status) !== -1 &&
                      (!props.row.times_inscritos ||
                        props.row.times_inscritos == 0)
                  "
                  type="is-purple"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="trophy"
                  outlined
                  @click="delLigaLevel2(props.row)"
                />
              </div>
              <div class="column is-full">
                <b-switch
                  type="is-success"
                  v-model="props.row.ativo"
                  @input="toggleFlag(props.row)"
                  >Ativo</b-switch
                >
                <b-switch
                  v-if="!userInfo.isInfluencer"
                  type="is-warning"
                  v-model="props.row.destaque"
                  @input="toggleDestaque(props.row)"
                  >Destaque</b-switch
                >
                <b-switch
                  type="is-warning"
                  v-model="props.row.destaque_influencer"
                  @input="toggleDestaqueInfluencer(props.row)"
                  >Destaque Influencer</b-switch
                >
              </div>
            </div>
          </b-table-column>

          <b-table-column
            v-if="'pre_venda,aberta,destaque'.indexOf(selectStatus) !== -1"
            field="ordem"
            label="Ordem"
            sortable
            right
            numeric
          >
            {{ props.row.ordem }}
          </b-table-column>

          <b-table-column field="id" label="Id" sortable right numeric>
            {{ props.row.id }}
          </b-table-column>

          <b-table-column
            field="influencer.nome_midia"
            label="Influenciador"
            sortable
            searchable
            left
          >
            {{ props.row.influencer.nome_midia }}
          </b-table-column>

          <b-table-column
            field="liga_tipo.descricao"
            label="Tipo"
            sortable
            searchable
            left
          >
            {{ props.row.liga_tipo.descricao }}
          </b-table-column>

          <b-table-column
            field="apuracao_tipo.descricao"
            label="Apuração"
            sortable
            searchable
            left
          >
            {{ props.row.apuracao_tipo.descricao }}
          </b-table-column>

          <b-table-column
            field="rodada_inicio"
            label="Rodadas"
            sortable
            searchable
            right
            numeric
          >
            <div>
              {{ props.row.rodada_inicio }} - {{ props.row.rodada_final }}
            </div>
          </b-table-column>

          <b-table-column field="status" label="Status" sortable centered>
            <span
              :class="{
                'tag is-danger': props.row.status == 'encerrada',
                'tag is-warning': props.row.status == 'em_andamento',
                'tag is-success': props.row.status == 'aberta',
                'tag is-info': props.row.status == 'pre_venda',
              }"
            >
              {{ props.row.status }}
            </span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <table>
            <thead>
              <th>Id</th>
              <th>Título</th>
              <th>Valores</th>
              <th>Limites</th>
              <th>Parâmetros</th>
              <!-- <th>Id</th>
              <th>Salas</th>
              <th>Inscrição</th>
              <th>%Escala</th>
              <th>%Influenciador</th>
              <th>Qtd.Prevista</th>
              <th>Limite Inscrições</th>
              <th>Limite Inscrições Usuário</th>
              <th>Limite Reserva</th>
              <th>Senha</th>
              <th>Capitão</th>
              <th>Clonagem</th> -->
            </thead>
            <tbody>
              <tr v-for="sala of props.row.salas" :key="sala.id_sala">
                <td>{{ sala.id_sala }}</td>
                <td>{{ sala.titulo }}</td>
                <td>
                  <div class="columns is-multiline is-gapless">
                    <div class="column is-full">
                      <label class="subtitle">Inscrição:</label>
                      {{ sala.valor }}
                    </div>
                    <div class="column is-full">
                      <label class="subtitle">%Escala:</label>
                      {{ sala.percent_escala }}
                    </div>
                    <div class="column is-full">
                      <label class="subtitle">%Influenciador:</label>
                      {{ sala.percent_sala }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="columns is-multiline is-gapless">
                    <div class="column is-full">
                      <label class="subtitle">Qtd.Prevista:</label>
                      {{ sala.prev_qtd_time }}
                    </div>
                    <div class="column is-full">
                      <label class="subtitle">Limite Inscrições:</label>
                      {{ sala.max_qtd_time }}
                    </div>
                    <div class="column is-full">
                      <label class="subtitle">Limite Inscrições Usuário:</label>
                      {{ sala.max_qtd_time_pssa }}
                    </div>
                    <div class="column is-full">
                      <label class="subtitle">Limite Reserva:</label>
                      {{ formatDate(sala.limite_reserva) }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="columns is-multiline is-gapless">
                    <div
                      v-if="edit.sala && edit.sala.id_sala == sala.id_sala"
                      class="column is-full"
                    >
                      <b-field label="Categorias" label-position="on-border">
                        <v-select
                          class="form-select multiple-select"
                          multiple
                          placeholder="Categorias"
                          :options="opcCategorias"
                          v-model="edit.sala.categorias"
                        >
                        </v-select>
                        <p
                          class="control centralize"
                          style="padding: 0.5em;"
                          @click="saveSalaCategorias(sala)"
                        >
                          <b-icon
                            type="is-success"
                            pack="fas"
                            size="is-small"
                            icon="check"
                          />
                        </p>
                        <p
                          class="control centralize"
                          style="padding: 0.5em;"
                          @click="cancelSalaCategorias()"
                        >
                          <b-icon
                            type="is-danger"
                            pack="fas"
                            size="is-small"
                            icon="times"
                          />
                        </p>
                      </b-field>
                    </div>
                    <div
                      v-else
                      class="column is-full"
                      @click="editSalaCategorias(sala)"
                    >
                      <label class="subtitle">Categorias:</label>
                      {{ formatCategorias(sala.categorias) }}
                      <span style="padding: 0.5em;">
                        <b-icon
                          type="is-warning"
                          pack="fas"
                          size="is-small"
                          icon="edit"
                        />
                      </span>
                    </div>
                    <div class="column is-full">
                      <label class="subtitle">Senha:</label> {{ sala.senha }}
                    </div>
                    <div class="column is-full">
                      <label class="subtitle">Capitão:</label>
                      {{ sala.capitao == 1 ? "Sim" : "Não" }}
                    </div>
                    <div class="column is-full">
                      <label class="subtitle">Clonagem:</label>
                      {{ sala.clonagem == 1 ? "Sim" : "Não" }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>Nenhum Registro.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>

    <div
      v-if="ligasForm"
      class="columns is-multiline is-gapless has-background-bluedark2"
    >
      <b-tabs type="is-toggle" expanded>
        <b-tab-item label="Dados Gerais" icon="info" icon-pack="fas">
          <div class="columns is-multiline">
            <div class="column">
              <b-field label="Ativo?">
                <b-switch type="is-success" v-model="form.ativo"></b-switch>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Influencer">
                <b-select
                  v-model="form.id_influencer"
                  required
                  :disabled="ligasFormAction == 'view'"
                  @input="setInfluencer()"
                >
                  <option v-for="op of influencers" :key="op.id" :value="op.id">
                    {{ op.nome_midia }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Tipo">
                <b-select
                  v-model="form.id_liga_tipo"
                  @input="changeType()"
                  required
                  :disabled="ligasFormAction == 'view'"
                >
                  <option v-for="op of tipo_liga" :key="op.slug" :value="op.id">
                    {{ op.descricao }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Tipo Apuração">
                <b-select
                  v-model="form.id_tipo_apuracao"
                  required
                  :disabled="ligasFormAction == 'view'"
                >
                  <option
                    v-for="op of tipo_apuracao"
                    :key="op.id"
                    :value="op.id"
                  >
                    {{ op.descricao }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column" v-if="form.id_liga_tipo == 2">
              <b-field label="Mês Inicial">
                <b-select
                  v-model="form.mes"
                  @input="setRodadas(form.mes)"
                  :disabled="ligasFormAction == 'view'"
                >
                  <option
                    v-for="(rodada, index) of rodadas"
                    :key="index"
                    :value="rodada.mes_rodada"
                  >
                    {{ rodada.mes_extenso }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column" v-if="form.id_liga_tipo == 2">
              <b-field label="Mês Final">
                <b-select
                  v-model="form.mes_final"
                  :disabled="ligasFormAction == 'view'"
                >
                  <option
                    v-for="(rodada, index) of rodadas"
                    :key="index"
                    :value="rodada.mes_rodada"
                  >
                    {{ rodada.mes_extenso }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div
              class="column"
              v-if="
                form.id_liga_tipo == 1 ||
                  form.id_liga_tipo == 3 ||
                  form.id_liga_tipo == 4
              "
            >
              <b-field label="Rodada Início">
                <b-numberinput
                  v-model="form.rodada_inicio"
                  controls-position="compact"
                  min="1"
                  max="38"
                  required
                  validation-message="Valor entre 1 e 38"
                  :disabled="ligasFormAction == 'view'"
                ></b-numberinput>
              </b-field>
            </div>

            <div
              class="column"
              v-if="
                form.id_liga_tipo == 1 ||
                  form.id_liga_tipo == 3 ||
                  form.id_liga_tipo == 4
              "
            >
              <b-field label="Rodada Final">
                <b-numberinput
                  v-model="form.rodada_final"
                  controls-position="compact"
                  min="1"
                  max="38"
                  required
                  validation-message="Valor entre 1 e 38"
                  :disabled="ligasFormAction == 'view'"
                ></b-numberinput>
              </b-field>
            </div>

            <div class="column is-full">
              <label>Descrição</label>
              <mc-wysiwyg
                v-model="form.descricao"
                :disabled="ligasFormAction == 'view'"
              ></mc-wysiwyg>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item icon="door-open" icon-pack="fas">
          <template slot="header">
            <b-icon icon="door-open"></b-icon>

            <span style="margin-right: 0.5em;">
              Salas
            </span>

            <b-button
              v-if="ligasFormAction !== 'view'"
              type="is-success"
              size="is-small"
              icon-pack="fas"
              icon-left="plus"
              @click="$refs.salaForm.addSala()"
              rounded
            ></b-button>
          </template>

          <div class="column is-full sala-bd">
            <Salas
              ref="salaForm"
              :ligasFormAction="ligasFormAction"
              :tipoLiga="form.id_liga_tipo"
              :influencer="selectedInfluencer"
              :salas="form.salas"
              @change="(val) => handlerSala(val)"
            ></Salas>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>

    <div
      v-if="ligasFormLevel2"
      class="columns is-multiline notification has-background-bluedark2"
    >
      <div class="column is-full sala-bd">
        <SalasNv2
          :salasNv2="salasLevel2"
          v-on:select="ligaLevel2 = $event"
        ></SalasNv2>
      </div>
    </div>
  </section>
</template>

<script>
import Salas from "./SalaForm";
import SalasNv2 from "./SalaNv2Form";
import Moment from "moment";
import vSelect from "vue-select";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "Ligas",
  components: {
    Salas,
    SalasNv2,
    "v-select": vSelect,
  },
  data() {
    return {
      envMode: "",
      selectStatus: "aberta",
      ligas: [],
      ligasTable: false,
      ligasForm: false,
      ligasFormAction: "",
      ligasFormLevel2: false,
      ligaLevel2: [],
      flagSelect: {},
      salas: [],
      salasLevel2: [],
      categorias: [],
      isLoading: false,
      form: {
        ativo: true,
        id_liga: 0,
        id_liga_tipo: 0,
        id_tipo_apuracao: 0,
        id_influencer: 0,
        rodada_inicio: 0,
        rodada_final: 0,
        mes: null,
        descricao: "",
        salas: [],
      },
      reseted_form: {
        id_liga: 0,
        id_liga_tipo: 0,
        id_tipo_apuracao: 0,
        id_influencer: 0,
        rodada_inicio: 0,
        rodada_final: 0,
        mes: null,
        descricao: "",
        salas: [],
      },
      data: [],
      influencers: [],
      selectedInfluencer: {},
      rodadas: [],
      fechamento: [],
      tipo_liga: [],
      tipo_apuracao: [],
      mes: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      edit: {
        sala: null,
      },
    };
  },
  computed: {
    ...mapGetters(["userId", "userInfo", "mercado"]),
    opcCategorias() {
      let categorias = this.categorias;
      let selected = this.edit.sala.categorias;

      if (selected) {
        categorias = categorias.filter(
          (el) => selected.findIndex((s) => s.value == el.id) == -1
        );
      }

      return categorias.map((el) => {
        return {
          label: el.descricao,
          value: el.id,
        };
      });
    },
  },
  async created() {
    let loader = this.$loading.show();

    this.envMode = process.env.NODE_ENV;

    this.tipo_liga = await this.getTypesLeague();
    this.tipo_apuracao = await this.getTypesApuracao();
    this.rodadas = await this.getRodadas();
    this.fechamento = await this.getFechamento();
    this.influencers = (await this.getInfluencers()).data;
    this.categorias = (await this.getCategorias()).data;
    this.ligas = await this.getLigasByStatus({ status: this.selectStatus });

    for (const rodada of this.rodadas) {
      let month = Number(rodada.mes_rodada.substring(5, 7));
      rodada.mes_extenso = this.mes[month - 1];
    }

    await this.reOrder();

    this.ligasTable = true;
    this.ligasForm = false;
    loader.hide();
  },
  methods: {
    ...mapActions([
      "getRodadas",
      "getFechamento",
      "getTypesLeague",
      "getTypesApuracao",
      "getLigasByStatus",
      "getLigaLevel2",
      "addNewLeague",
      "flagLeague",
      "sortLeague",
      "destaqueLeague",
      "destaqueLeagueInfluencer",
      "deleteLeague",
      "getInfluencers",
      "setNextRoundTest",
      "delSubSalas",
      "getCategorias",
      "setRelSalaCategorias",
    ]),
    formatCategorias(categorias) {
      let strCategorias = "";
      if (categorias) {
        categorias.map(
          (el) =>
            (strCategorias += (strCategorias !== "" ? ", " : "") + el.descricao)
        );
      }
      return strCategorias;
    },
    dragstart(payload) {
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
      payload.event.dataTransfer.effectAllowed = "copy";
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = "copy";
      payload.event.target.closest("tr").classList.add("is-selected");
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      payload.event.preventDefault();
    },
    async drop(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      const droppedOnRowIndex = payload.index;
      let index = this.ligas.findIndex((i) => i.id == this.draggingRow.id);
      if (index !== -1) {
        this.ligasTable = false;
        this.isLoading = true;
        let oldOrder = this.draggingRowIndex + 1;
        let newOrder = droppedOnRowIndex + 1;
        this.ligas.map((l) => {
          if (newOrder > oldOrder) {
            l.ordem =
              l.ordem >= oldOrder && l.ordem <= newOrder
                ? l.ordem - 1
                : l.ordem;
          } else if (newOrder < oldOrder) {
            l.ordem =
              l.ordem >= newOrder && l.ordem <= oldOrder
                ? l.ordem + 1
                : l.ordem;
          }
        });
        this.ligas[index].ordem = newOrder;
        await this.sortLeague({
          userId: this.userId,
          ligas: this.ligas.map((l) => {
            return { id: l.id, order: l.ordem };
          }),
        });
        this.$toasted.success(
          `Alterada a ordem da Liga ${this.draggingRow.id} de ${oldOrder} para ${newOrder}`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
        this.isLoading = false;
        this.ligasTable = true;
      } else {
        this.$toasted.error(
          `Alteração da ordenação não realizada pois a ordem da Liga ${this.draggingRow.id} não foi localizada!`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      }
    },
    async filterStatus(status) {
      this.selectStatus = status;

      this.ligasTable = false;
      this.ligasForm = false;

      let loader = this.$loading.show();

      this.ligas = await this.getLigasByStatus({ status: this.selectStatus });

      await this.reOrder();

      this.ligasTable = true;

      loader.hide();
    },
    async toggleFlag(liga) {
      this.flagSelect = liga;

      let active = liga.ativo;
      let title = active ? "Ativar Liga" : "Desativar Liga";
      let msg = active
        ? "A liga será ativada e liberada para a visualização/inscrições dos usuários."
        : "A liga será desativada e ficará oculta da lista para os usuários.";
      let confirm = active ? "Ativar" : "Desativar";

      this.$buefy.dialog.confirm({
        title: title,
        message:
          "<p>Tem certeza que deseja alterar o flag de ativação?</p><p>" +
          msg +
          "</p>",
        confirmText: confirm,
        cancelText: "Cancelar",
        type: active ? "is-success" : "is-danger",
        hasIcon: true,
        onCancel: async () => {
          this.flagSelect.ativo = !this.flagSelect.ativo;
        },
        onConfirm: async () => {
          try {
            this.isLoading = true;

            let data = await this.flagLeague({
              userId: this.userId,
              id_liga: this.flagSelect.id,
            });

            if (data.affectedRows == 1) {
              this.$toasted.success("Flag alterado com sucesso!", {
                duration: 3000,
                position: "top-center",
              });
            }

            this.isLoading = false;
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error(
              "Ocorreu um problema ao tentar alterar o flag!",
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        },
      });
    },
    async toggleDestaque(liga) {
      this.flagSelect = liga;

      let destaque = liga.destaque;
      let title = destaque
        ? "Ativar Destaque da Liga"
        : "Desativar Destaque da Liga";
      let msg = destaque
        ? "A liga ficará em destaque na visualização/inscrições dos usuários."
        : "O destaque da liga será desativada e será exibida na lista para os usuários de acordo com a ordenação definida.";
      let confirm = destaque ? "Ativar Destaque" : "Desativar Destaque";

      this.$buefy.dialog.confirm({
        title: title,
        message:
          "<p>Tem certeza que deseja alterar o flag do destaque da Liga?</p><p>" +
          msg +
          "</p>",
        confirmText: confirm,
        cancelText: "Cancelar",
        type: destaque ? "is-warning" : "is-danger",
        hasIcon: true,
        onCancel: async () => {
          this.flagSelect.destaque = !this.flagSelect.destaque;
        },
        onConfirm: async () => {
          try {
            this.isLoading = true;

            let data = await this.destaqueLeague({
              userId: this.userId,
              id_liga: this.flagSelect.id,
            });

            if (data.affectedRows == 1) {
              this.$toasted.success("Flag de destaque alterado com sucesso!", {
                duration: 3000,
                position: "top-center",
              });
            }

            this.isLoading = false;
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error(
              "Ocorreu um problema ao tentar alterar o flag de destaque!",
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        },
      });
    },
    async toggleDestaqueInfluencer(liga) {
      this.flagSelect = liga;

      let destaque_influencer = liga.destaque_influencer;
      let title =
        (destaque_influencer ? "Ativar" : "Desativar") +
        " Destaque do Game na página do influenciador ";
      let msg =
        "Apenas na página do influenciador, " +
        (destaque_influencer
          ? "A liga ficará em destaque na visualização/inscrições dos usuários."
          : "O destaque da liga será desativada e será exibida na lista para os usuários de acordo com a ordenação definida.");
      let confirm = destaque_influencer
        ? "Ativar Destaque"
        : "Desativar Destaque";

      this.$buefy.dialog.confirm({
        title: title,
        message:
          "<p>Tem certeza que deseja alterar o flag do destaque da Liga?</p><p>" +
          msg +
          "</p>",
        confirmText: confirm,
        cancelText: "Cancelar",
        type: destaque_influencer ? "is-warning" : "is-danger",
        hasIcon: true,
        onCancel: async () => {
          this.flagSelect.destaque_influencer = !this.flagSelect
            .destaque_influencer;
        },
        onConfirm: async () => {
          try {
            this.isLoading = true;

            let data = await this.destaqueLeagueInfluencer({
              userId: this.userId,
              id_liga: this.flagSelect.id,
            });

            if (data.affectedRows == 1) {
              this.$toasted.success("Flag de destaque alterado com sucesso!", {
                duration: 3000,
                position: "top-center",
              });
            }

            this.isLoading = false;
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error(
              "Ocorreu um problema ao tentar alterar o flag de destaque!",
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        },
      });
    },
    async addLiga() {
      // this.$router.push("/cms/ligas/adicionar");
      this.ligasTable = false;
      this.ligasForm = true;
      this.ligasFormAction = "add";
    },
    async copyLiga(liga) {
      await this.fillForm(liga);

      //excluído os ids para que o form identifique como uma inclusão de uma nova liga, apesar de ter sido feito uma cópia de uma liga já existente
      delete this.form.id_liga;
      this.form.salas.map((s) => {
        delete s.id_sala;
        s.subSalas.map((sS) => {
          delete sS.id_sub_sala;
        });
      });

      this.ligasTable = false;
      this.ligasForm = true;
      this.ligasFormAction = "copy";
    },
    async resetForm() {
      let loader = this.$loading.show();

      this.ligasForm = false;
      this.ligasFormLevel2 = false;
      this.ligasTable = false;

      this.form = JSON.parse(JSON.stringify(this.reseted_form));
      this.influencers = (await this.getInfluencers()).data;
      this.ligas = await this.getLigasByStatus({ status: this.selectStatus });

      await this.reOrder();

      this.ligasTable = true;
      loader.hide();
    },
    async reOrder() {
      let qtdLigas = this.ligas.filter((l) => l.ordem > 0).length;

      if (this.ligas.length > 0) {
        let newLigas = this.ligas
          .filter((l) => !l.ordem || l.ordem == 0)
          .map((l) => {
            l.ordem = ++qtdLigas;
            return { id: l.id, order: l.ordem };
          });

        if (newLigas.length > 0) {
          let response = await this.sortLeague({
            userId: this.userId,
            ligas: newLigas,
          });

          let result = true;
          response.map((r) => {
            if (r.affectedRows === 0) {
              result = false;
            }
          });

          if (result) {
            this.$toasted.success(`Ordenação realizada para novas ligas!`, {
              duration: 3000,
              position: "top-center",
            });
          } else {
            this.$toasted.error(
              `Ocorreu um problema na ordenação das novas ligas!`,
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        }
      }
    },
    async saveLiga() {
      if (await this.validLiga()) {
        try {
          this.isLoading = true;

          let params = await this.prepareParams();

          for (const param of params) {
            let data = await this.addNewLeague({
              userId: this.userId,
              form: param,
            });

            if (data.error) {
              let acao =
                "add,copy".indexOf(this.ligasFormAction) !== -1
                  ? "Inclusão"
                  : "Edição";
              this.$toasted.error(
                acao + " da Liga não permitida! " + data.error,
                {
                  duration: 3000,
                  position: "top-center",
                }
              );
            } else {
              let acao =
                "add,copy".indexOf(this.ligasFormAction) !== -1
                  ? "incluída"
                  : "alterada";
              this.$toasted.success("Liga " + acao + " com sucesso!", {
                duration: 3000,
                position: "top-center",
              });
            }
          }
          this.resetForm();
          this.isLoading = false;
        } catch (err) {
          this.isLoading = false;
          console.log(err);
          this.$toasted.error(
            "Ocorreu um problema ao tentar registrar a Liga!",
            {
              duration: 3000,
              position: "top-center",
            }
          );
        }
      }
    },
    async validLiga() {
      let valid = await this.validRodada();

      if (valid) {
        let msgError = "";

        if (!this.form.id_influencer || this.form.id_influencer == "") {
          msgError += "<p>* Influenciador não selecionado</p>";
        }
        if (!this.form.id_liga_tipo || this.form.id_liga_tipo == "") {
          msgError += "<p>* Tipo da Liga não selecionado</p>";
        }
        if (!this.form.id_tipo_apuracao || this.form.id_tipo_apuracao == "") {
          msgError += "<p>* Tipo de Apuração não selecionado</p>";
        }

        if (this.form.salas.length == 0) {
          msgError += "<p>* Não existem salas definidas para a liga</p>";
        } else {
          let index = 0;
          let comReserva = false;
          let semReserva = false;
          for (const sala of this.form.salas) {
            if (!comReserva) {
              comReserva =
                sala.limite_reserva &&
                sala.limite_reserva !== "" &&
                sala.limite_reserva !== null;
            }
            if (!semReserva) {
              semReserva =
                !sala.limite_reserva ||
                sala.limite_reserva == "" ||
                sala.limite_reserva == null;
            }

            index++;
            if (sala.valor === null || sala.valor === "") {
              msgError +=
                "<p>* Valor de inscrição não definido na sala " +
                index +
                ". Caso seja uma sala gratuita, favor informar o valor 0 (zero)</p>";
            }
            if (sala.percent_escala === null || sala.percent_escala === "") {
              msgError +=
                "<p>* % Escala10 não definido na sala " +
                index +
                ". Caso não possua um % para o Escala10, favor informar o valor 0 (zero)</p>";
            }
            if (sala.percent_sala === null || sala.percent_sala === "") {
              msgError +=
                "<p>* % Influenciador não definido na sala " +
                index +
                ". Caso não possua um % para o Influenciador, favor informar o valor 0 (zero)</p>";
            }
            if (!sala.premiacao || sala.premiacao.length == 0) {
              msgError +=
                "<p>* Não existem premiações definidas para a sala " +
                index +
                "</p>";
            } else {
              sala.percent_escala = sala.percent_escala
                ? Number(sala.percent_escala)
                : 0;
              sala.percent_sala = sala.percent_sala
                ? Number(sala.percent_sala)
                : 0;
              sala.prev_qtd_time = sala.prev_qtd_time
                ? Number(sala.prev_qtd_time)
                : 0;

              let percentTotal = 100 - sala.percent_escala - sala.percent_sala;
              let percent = Number(percentTotal.toFixed(4));
              let maiorLimiar = 0;
              for (const premiacao of sala.premiacao) {
                premiacao.limiar = premiacao.limiar
                  ? Number(premiacao.limiar)
                  : 0;

                let tipos = [];
                let percentSub = percent;
                if (premiacao.limiar <= 0) {
                  msgError +=
                    "<p>* sala " +
                    index +
                    " deverá possuir um limiar maior do que 0 (zero)</p>";
                } else if (
                  !premiacao.subsalas ||
                  premiacao.subsalas.length == 0
                ) {
                  msgError +=
                    "<p>* Não existem subsalas definidas para a sala " +
                    index +
                    " com limiar " +
                    premiacao.limiar.toString() +
                    "</p>";
                } else {
                  maiorLimiar =
                    premiacao.limiar > maiorLimiar
                      ? premiacao.limiar
                      : maiorLimiar;
                  for (const subsala of premiacao.subsalas) {
                    subsala.percent_sub = subsala.percent_sub
                      ? Number(Number(subsala.percent_sub).toFixed(4))
                      : 0;
                    percentSub -= subsala.percent_sub;
                    percentSub = Number(percentSub.toFixed(4));
                    let percentRnk = 100;
                    if (
                      !subsala.tipo ||
                      subsala.tipo == 0 ||
                      subsala.tipo == ""
                    ) {
                      msgError +=
                        "<p>* Tipo de premiação não definida na sala " +
                        index +
                        " com limiar " +
                        premiacao.limiar.toString() +
                        " com % " +
                        subsala.percent_sub +
                        "</p>";
                    } else if (
                      !subsala.ranking ||
                      subsala.ranking.length == 0
                    ) {
                      msgError +=
                        "<p>* Não existem rankings definidos para a sala " +
                        index +
                        " com limiar " +
                        premiacao.limiar.toString() +
                        " na premiação " +
                        subsala.tipo_name.toUpperCase() +
                        "</p>";
                    } else {
                      for (const ranking of subsala.ranking) {
                        ranking.percent_posicao = ranking.percent_posicao
                          ? Number(Number(ranking.percent_posicao).toFixed(4))
                          : 0;
                        percentRnk -= ranking.percent_posicao;
                        percentRnk = Number(percentRnk.toFixed(4));
                      }
                      if (percentRnk > 100) {
                        msgError +=
                          "<p>* A soma dos percentuais do ranking deverão totalizar 100%</p><p>* Premiação " +
                          subsala.tipo_name.toUpperCase() +
                          " da sala " +
                          index +
                          " com " +
                          Math.abs(percentRnk).toString() +
                          "% excedido</p>";
                      }
                      if (tipos.indexOf(subsala.tipo) !== -1) {
                        msgError +=
                          "<p>* Premiação do tipo " +
                          subsala.tipo_name.toUpperCase() +
                          " em duplicidade na sala " +
                          index +
                          " com limmiar " +
                          premiacao.limiar.toString() +
                          "</p>";
                      } else {
                        tipos.push(subsala.tipo);
                      }
                    }
                  }
                  if (percentSub !== 0) {
                    // msgError += "<p>* Existe um saldo de "+percent+"% a ser distribuído na sala "+index+" com limmiar "+premiacao.limiar.toString()+"</p><p>A soma dos percentuais dessa sala deverão totalizar "+percentTotal+"%</p>"
                    msgError +=
                      "<p>* A soma dos percentuais de prêmios da sala" +
                      index +
                      " com limmiar " +
                      premiacao.limiar.toString() +
                      " deverão totalizar " +
                      percentTotal +
                      "%</p><p>* Total de prêmios da sala " +
                      index +
                      " com limmiar " +
                      premiacao.limiar.toString() +
                      ": " +
                      percentSub +
                      "%</p>";
                  }
                }
              }
              if (sala.prev_qtd_time > 0 && sala.prev_qtd_time < maiorLimiar) {
                msgError +=
                  "<p>* sala " +
                  index +
                  " com quantidade prevista menor do que o maior limiar</p>";
              }
            }
          }

          if (comReserva && semReserva) {
            msgError +=
              "<p>* Todas as salas deverão permitir a reserva, caso alguma permita. Preencha ou retire a data limite para reserva em todas as salas.</p>";
          }
        }

        if (msgError !== "") {
          valid = false;
          this.$buefy.dialog.alert({
            title: "ATENÇÃO!",
            message: msgError,
            type: "is-danger",
            hasIcon: true,
          });
        }
      }

      return valid;
    },
    async prepareParams() {
      let params = JSON.parse(JSON.stringify(this.form));

      let reserva = false;
      params.salas = params.salas.map((sala) => {
        sala.limite_reserva = sala.limite_reserva
          ? Moment(sala.limite_reserva).format("YYYY-MM-DD HH:mm:ss")
          : null;
        reserva =
          sala.limite_reserva &&
          sala.limite_reserva !== "" &&
          sala.limite_reserva !== null;
        return sala;
      });
      params.reserva = reserva;

      for (const sala of params.salas) {
        let premios = [];
        for (const premiacao of sala.premiacao) {
          for (const subsala of premiacao.subsalas) {
            premios.push(subsala);
          }
        }
        sala.premiacao = premios;
      }

      //Tiro Curto - Liga em Lote por Rodada
      if (
        params.id_liga_tipo == 1 &&
        params.rodada_inicio !== params.rodada_final
      ) {
        let rodadaInicial = params.rodada_inicio;
        let rodadaFinal = params.rodada_final;
        params.rodada_final = params.rodada_inicio;
        let loteParams = [JSON.parse(JSON.stringify(params))];

        for (let rodada = rodadaInicial + 1; rodada <= rodadaFinal; rodada++) {
          params.rodada_inicio = rodada;
          params.rodada_final = rodada;
          loteParams.push(JSON.parse(JSON.stringify(params)));
        }

        params = loteParams;
      } else if (params.id_liga_tipo == 2 && params.mes !== params.mes_final) {
        let mesInicial = params.mes;
        let mesFinal = params.mes_final;
        params.mes_final = params.mes;
        let loteParams = [JSON.parse(JSON.stringify(params))];

        for (const rodada of this.rodadas) {
          if (rodada.mes_rodada > mesInicial && rodada.mes_rodada <= mesFinal) {
            params.mes = rodada.mes_rodada;
            params.mes_final = rodada.mes_rodada;
            params.rodada_inicio = rodada.rodada_ini;
            params.rodada_final = rodada.rodada_fim;
            loteParams.push(JSON.parse(JSON.stringify(params)));
          }
        }

        params = loteParams;
      } else {
        params = [params];
      }

      return params;
    },
    async viewLiga(liga) {
      await this.fillForm(liga);

      this.ligasTable = false;
      this.ligasForm = true;
      this.ligasFormAction = "view";
    },
    async editLiga(liga) {
      await this.fillForm(liga);

      this.ligasTable = false;
      this.ligasForm = true;
      this.ligasFormAction = "edit";
    },
    async deleteLiga(liga) {
      if (liga) {
        await this.fillForm(liga);
        this.ligasFormAction = "delete";

        this.$buefy.dialog.confirm({
          title: "Exclusão da Liga",
          message: "Tem certeza que deseja excluir esta Liga?",
          confirmText: "Excluir",
          cancelText: "Cancelar",
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            try {
              this.isLoading = true;

              let data = await this.deleteLeague({
                userId: this.userId,
                id_liga: this.form.id_liga,
              });

              if (data[0][0].flag == 1) {
                this.$toasted.success("Liga excluída com sucesso!", {
                  duration: 3000,
                  position: "top-center",
                });

                this.resetForm();
              } else {
                this.$toasted.error(
                  "<p>Exclusão da Liga não permitida!</p><p>" +
                    data[0][0].msg +
                    "</p>",
                  {
                    duration: 3000,
                    position: "top-center",
                  }
                );
              }

              this.isLoading = false;
            } catch (err) {
              this.isLoading = false;
              console.log(err);
              this.$toasted.error(
                "Ocorreu um problema ao tentar excluir a Liga!",
                {
                  duration: 3000,
                  position: "top-center",
                }
              );
            }
          },
        });
      }
    },
    async delLigaLevel2(liga) {
      this.salasLevel2 = await this.getLigaLevel2({
        id_liga: liga.id,
      });

      this.ligasTable = false;
      this.ligasFormLevel2 = true;
      this.ligasFormAction = "redo";
    },
    async redoLiga() {
      if (this.ligaLevel2.length == 0) {
        this.$toasted.error("Nenhum registro selecionado!", {
          duration: 3000,
          position: "top-center",
        });
        return;
      }

      try {
        let res = await this.delSubSalas({
          subSalas: this.ligaLevel2,
        });

        if (res.error) {
          this.$toasted.error(res.error, {
            duration: 3000,
            position: "top-center",
          });
        } else {
          if (res.result) {
            let del = { ok: 0, erro: 0 };
            let msgErro = "";

            for (const result of res.result) {
              if (result.cod == 1) {
                del.ok++;
              } else if (result.cod == 0) {
                del.erro++;

                if (msgErro.indexOf(result.msg) == -1) {
                  msgErro += (msgErro !== "" ? "; " : "") + result.msg;
                }
              }
            }

            if (del.erro > 0) {
              this.$buefy.dialog.alert({
                title: "ATENÇÃO!",
                message: `<p>Ocorreu um problema na exclusão de ${del.erro} subsala(s).</p><p>${msgErro}</p>`,
                type: "is-danger",
                hasIcon: true,
              });
            }

            if (del.ok > 0) {
              this.$toasted.success(
                `${del.ok} SubSala(s) excluída(s) com sucesso!`,
                {
                  duration: 5000,
                  position: "top-center",
                }
              );
              this.resetForm();
            }
          } else {
            this.$toasted.error(
              "Ocorreu um problema na exclusão das SubSalas.",
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        }
      } catch (err) {
        this.$toasted.error("Não foi possível salvar", {
          duration: 3000,
          position: "top-center",
        });
      }
    },
    async fillForm(liga) {
      let formLiga = await this.getLigasByStatus({
        status: this.selectStatus,
        id_liga: liga.id,
      });

      this.form.ativo = formLiga.ativo;
      this.form.id_liga = formLiga.id;
      this.form.id_liga_tipo = formLiga.liga_tipo.id;
      this.form.id_tipo_apuracao = formLiga.apuracao_tipo.id;
      this.form.id_influencer = formLiga.influencer.id;
      this.form.rodada_inicio = formLiga.rodada_inicio;
      this.form.rodada_final = formLiga.rodada_final;
      this.form.mes = formLiga.mes;
      this.form.descricao = formLiga.descricao;

      this.setInfluencer();

      this.form.salas = formLiga.salas.map((sala) => {
        sala.limite_reserva = sala.limite_reserva
          ? new Date(sala.limite_reserva)
          : null;
        return sala;
      });
    },
    async setInfluencer() {
      let influencer = this.influencers.find(
        (i) => i.id == this.form.id_influencer
      );
      if (influencer) {
        this.selectedInfluencer = influencer;

        if (influencer.percent_escala) {
          this.form.salas.map((s) => {
            s.percent_escala = influencer.percent_escala;
          });
        }
        if (influencer.percent_sala) {
          this.form.salas.map((s) => {
            s.percent_sala = influencer.percent_sala;
          });
        }
      }
    },
    async changeType() {
      this.form.mes = null;
      this.form.rodada_inicio = 0;
      this.form.rodada_final = 0;

      if (this.form.id_liga_tipo == 4) {
        if (this.rodadas) {
          this.form.rodada_inicio = this.rodadas[0].rodada_ini;
          this.form.rodada_final = this.rodadas[
            this.rodadas.length - 1
          ].rodada_fim;
        }
      }
    },
    async setRodadas(mes) {
      if (this.rodadas && this.rodadas.length > 0) {
        for (const rodada of this.rodadas) {
          if (rodada.mes_rodada == mes) {
            this.form.rodada_inicio = rodada.rodada_ini;
            this.form.rodada_final = rodada.rodada_fim;
          }
        }
      }
    },
    handlerSala(salas) {
      this.form.salas = salas;
    },
    async validRodada() {
      let valid = true;

      if (this.form.rodada_inicio > 0 && this.form.rodada_final > 0) {
        if (this.form.rodada_inicio < this.mercado.rodada_atual) {
          valid = false;
          this.$buefy.dialog.alert({
            title: "ATENÇÃO!",
            message: "Rodada inicial deverá ser maior ou igual a rodada atual",
            type: "is-danger",
            hasIcon: true,
          });
        }
        if (this.form.rodada_final < this.form.rodada_inicio) {
          valid = false;
          this.$buefy.dialog.alert({
            title: "ATENÇÃO!",
            message: "Rodada final deverá ser maior ou igual a rodada inicial",
            type: "is-danger",
            hasIcon: true,
          });
          this.form.rodada_final = this.form.rodada_inicio;
        }
      }
      if (this.form.rodada_final > 38) {
        valid = false;
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "Rodada final deverá ser menor ou igual a 38",
          type: "is-danger",
          hasIcon: true,
        });
        this.form.rodada_final = 38;
      }

      if ("add,copy".indexOf(this.ligasFormAction) !== -1) {
        this.form.rodada_inicio = Number(this.form.rodada_inicio);
        this.form.rodada_final = Number(this.form.rodada_final);

        if (this.form.rodada_inicio > 0 && this.form.rodada_final > 0) {
          let dataAtual = new Date();
          let anoAtual = dataAtual.getFullYear();
          let rodadas = "";
          dataAtual = Moment(dataAtual);

          for (
            let rodada = this.form.rodada_inicio;
            rodada <= this.form.rodada_final;
            rodada++
          ) {
            for (const fechaRodada of this.fechamento) {
              if (fechaRodada.id_rodada == rodada) {
                let dataFechamento = new Date(fechaRodada.fechamento_mercado);
                let anoRodada = dataFechamento.getFullYear();
                dataFechamento = Moment(dataFechamento);

                if (anoRodada == anoAtual && dataFechamento <= dataAtual) {
                  rodadas +=
                    (rodadas !== "" ? "," : "") +
                    fechaRodada.id_rodada.toString();
                }
              }
            }
          }
          if (rodadas !== "") {
            valid = false;
            this.$buefy.dialog.alert({
              title: "ATENÇÃO!",
              message: "Rodadas encerradas para inscrição: " + rodadas,
              type: "is-danger",
              hasIcon: true,
            });
          }
        }
      }

      return valid;
    },
    async nextRound() {
      let res;
      try {
        res = await this.setNextRoundTest();
        this.$buefy.dialog.alert({
          title: "Rodada",
          message: res.msg,
          type: "is-success",
          hasIcon: true,
          icon: "check",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } catch (err) {
        console.log(err);
        this.$buefy.dialog.alert({
          title: "Rodada",
          message: res.msg,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    formatDate(data) {
      let newDate = "";
      if (data && data !== "0000-00-00 00:00:00") {
        Moment.locale("pt-br");
        newDate = Moment(new Date(data));
        newDate = newDate.format("ddd, DD/MMM, HH:mm:ss");
      }
      return newDate;
    },
    editSalaCategorias(sala) {
      this.edit.sala = { ...sala };
      if (this.edit.sala.categorias.length) {
        this.edit.sala.categorias = this.edit.sala.categorias.map((c) => {
          return {
            label: c.descricao,
            value: c.id,
          };
        });
      }
    },
    cancelSalaCategorias() {
      this.edit.sala = null;
    },
    async saveSalaCategorias(sala) {
      let loader = this.$loading.show();
      try {
        let res = await this.setRelSalaCategorias({
          userId: this.userId,
          ligaId: this.edit.sala.id_liga,
          salaId: this.edit.sala.id_sala,
          categorias: this.edit.sala.categorias,
        });

        if (!res.error) {
          sala.categorias = this.edit.sala.categorias.map((c) => {
            return {
              id: c.value,
              descricao: c.label,
            };
          });
          this.edit.sala = null;
        } else {
          this.$buefy.dialog.alert({
            title: "Categorias",
            message: res.msg,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } catch (err) {
        console.log(err);
        this.$buefy.dialog.alert({
          title: "Categorias",
          message: err.msg,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }

      loader.hide();
    },
  },
};
</script>

<style scoped>
.toolbar {
  text-align: right;
  padding: 1.5em;
}
.sala-bd {
  padding: 0;
}
</style>
