<template>
  <section id="SalaNv2Form">

    <div v-if="render">

      <b-collapse
        class="card has-background-bluedark2"
        animation="slide"
        v-for="(sala, index1) of salas"
        :key="index1"
        :open="isOpen1 == index1"
        @open="isOpen1 = index1">
        <div
          slot="trigger"
          slot-scope="props"
          class="card-header has-background-bluedark"
          role="button">
          <p class="card-header-title">
            Valor: R$ {{ sala.valor.toFixed(2) }} {{ (sala.titulo) ? ' - Titulo: ' + sala.titulo : '' }} {{ (sala.max_qtd_time) ? ' - Qtd.Max.Times: ' + sala.max_qtd_time : '' }} {{ (sala.prev_qtd_time) ? ' - Prev.Qtd.Times: ' + sala.prev_qtd_time : '' }} {{ (sala.flag_capitao) ? ' - Com Capitão' : ' - Sem Capitão' }} {{ (sala.flag_clonagem) ? ' - Com Clonagem' : ' - Sem Clonagem' }}
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="content">

            <b-collapse
              class="card has-background-bluedark2"
              animation="slide"
              v-for="(limiar, index2) of sala.limiar"
              :key="index2"
              :open="isOpen2 == index2"
              @open="isOpen2 = index2">
              <div
                slot="trigger"
                slot-scope="props"
                class="card-header has-background-bluedark"
                role="button">
                <p class="card-header-title">
                  Limiar: {{ limiar.limiar }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
              <div class="card-content">
                <div class="content">

                  <b-table
                    blueDark2
                    :data="limiar.subSalas"
                    :mobile-cards="true"
                    checkable
                    :checked-rows.sync="checkedRows"
                    checkbox-position="left">

                    <template slot-scope="props" slot="header">
                      <div v-if="props.column.sortable" class="label-table centralize-row">
                        <span v-if="props.column.sortable">
                          <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
                        </span>
                        <p>{{ props.column.label }}</p>
                      </div>
                      <div v-else class="label-table centralize-row">
                        <p>{{ props.column.label }}</p>
                      </div>
                    </template>

                    <template slot-scope="props">

                      <b-table-column field="id" label="ID">
                        {{ props.row.id }}
                      </b-table-column>

                      <b-table-column field="slug" label="Slug">
                        {{ props.row.slug }}
                      </b-table-column>

                      <b-table-column field="desc" label="Descrição">
                        {{ props.row.desc }}
                      </b-table-column>

                    </template>

                    <template slot="empty">
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>
                                    <b-icon
                                        icon="emoticon-sad"
                                        size="is-large">
                                    </b-icon>
                                </p>
                                <p>Nenhum Registro.</p>
                            </div>
                        </section>
                    </template>
                  </b-table>

                </div>
              </div>
            </b-collapse>

          </div>
        </div>
      </b-collapse>

    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Moment from 'moment'
export default {
  name: "SalaNv2Form",
  props: {
    'salasNv2': {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      render: false,
      isOpen1: false,
      isOpen2: false,
      salas: {},
      checkedRows: []
    };
  },
  watch: {
    checkedRows: {
      handler: function(newVal, oldVal) {
        this.$emit("select", newVal);
      },
      deep: true
    }
  },
  created(){

    let auxSalas = []
    let auxLimiar = []

    this.salasNv2.map(s2 => {

      let index1 = s2.id_sala.toString()

      if (auxSalas.indexOf(index1) == -1){
        
        auxSalas.push(index1)
        
        this.salas[index1] = {
          id_sala: s2.id_sala,
          titulo: s2.titulo,
          valor: s2.valor,
          max_qtd_time: s2.max_qtd_time,
          prev_qtd_times: s2.prev_qtd_time,
          flag_capitao: s2.flag_capitao,
          flag_clonagem: s2.flag_clonagem,
          limiar: {}
        }
      }

      let index2 = index1 + s2.limiar.toString()

      if (auxLimiar.indexOf(index2) == -1){
        
        auxLimiar.push(index2)
        
        this.salas[index1]['limiar'][index2] = {
          limiar: s2.limiar,
          subSalas: []
        }
      }

      this.salas[index1]['limiar'][index2]['subSalas'].push({
        id: s2.id_sub_sala_nv2,
        slug: s2.slug_tipo_sub_sala,
        desc: s2.desc_sub_sala_nv2
      })

      // let index3 = this.salas[index1]['limiar'][index2]['subSalas'].length - 1
      // this.checkedRows.push(this.salas[index1]['limiar'][index2]['subSalas'][index3])
    })

    this.render = true
  }
};
</script>

<style scoped>
</style>
