import { render, staticRenderFns } from "./SalaNv2Form.vue?vue&type=template&id=54b8f338&scoped=true&"
import script from "./SalaNv2Form.vue?vue&type=script&lang=js&"
export * from "./SalaNv2Form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b8f338",
  null
  
)

export default component.exports